/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "getSimulationOutput",
            "endpoint": "https://rr2gipdlm0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://uqdqsijo2vhbrp7n7cp4uqvgsy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OM2s88Psr",
    "aws_user_pools_web_client_id": "57svgk24ilksqnbcd500lcvfto",
    "oauth": {
        "domain": "kinetiksolutions.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "File-pvj44lfrsvat7hklhitua63aau-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "webUrls-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "recommendation-engine-scraped-data",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
